<template>
    <div class="container">
        <div class="container-body">
            <div :class="ClientWidth_number > 850 ?'body-box' : 'body-box-phone'">
                <el-row>
                    <el-col :span="6">
                        <div class="grid-content bg-purple title" style="height:5vw;width:120px;font-size:12px;"
                             v-if="ClientWidth_number > 850">
                            <h1>网页不存在</h1>
                        </div>
                        <div class="grid-content bg-purple title" style="height:70px;width:150px;font-size:12px;"
                             v-else>
                            <h1>网页不存在</h1>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :offset="3" :span="24">
                        <div class="grid-content bg-purple " style="display:flex;height:300px;width:300px;align-items:center;"
                             v-if="ClientWidth_number > 850">
                            <div>
                                <h1 style="color:#e7505a;font-size:50px;">404</h1>
                            </div>
                            <div style="height:200px;width:300px;margin-left:30px;;">
                                <div style="height:50px;width:200px;font-size:20px;text-align:center;line-height:50px;font-weight:600;">
                                    网页不存在
                                </div>
                                <div class="subtitle">
                                    <p style="margin-top:15px;">您访问的网页不存在，请检查链接后重新尝试。</p>
                                    <p style="margin-top:15px;">如果您认为这是系统错误，请联系我们。</p>
                                </div>
                                <div style="padding-left:100px;">
                                    <el-button @click="()=>{$router.go(-1)}" type="primary">返回</el-button>
                                    <el-button @click="()=>{$router.push('/home')}" type="success">首页</el-button>
                                </div>
                            </div>
                        </div>
                        <div class="grid-content bg-purple " style="display:flex;height:300px;width:410px;align-items:center;margin-right:10px;"
                             v-else>
                            <div>
                                <h1 style="color:#e7505a;font-size:50px;">404</h1>
                            </div>
                            <div style="height:200px;width:300px;margin:0 auto;">
                                <div style="height:50px;width:200px;font-size:20px;text-align:center;line-height:50px;color:#555555;">
                                    网页不存在
                                </div>
                                <div style="height:100px;width:200px;margin-left:10px;color:#555555;font-size:14px;">
                                    <p>您访问的网页不存在，请检查链接后重新尝试。</p>
                                    <p>如果您认为这是系统错误，请联系我们。</p>
                                </div>
                                <div style="padding-left:50px;">
                                    <el-button @click="()=>{$router.go(-1)}" type="primary">返回</el-button>
                                    <el-button @click="()=>{$router.push('/home')}" type="success">首页</el-button>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                xxx: "\u3000\u3000",
                ClientWidth_number: document.documentElement.clientWidth,
            };
        },
        created() {
            this.getClientWidth();

        },
        methods: {
            getClientWidth() {
                window.onresize = () => {
                    this.$parent.getClientWidth();
                    this.ClientWidth_number = document.documentElement.clientWidth;
                };
            },
        },
    };
</script>

<style lang='less' scoped>
    @baseWidth: 1980;
    .pxfix(@w) {
        width: (@w / @baseWidth) * 100vw;
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .container-body {
        .pxfix(1980);
        min-height: 700px;
        margin: 0 auto;
        background: #eff3f8;
        // margin-top: 20px;
        display: flex;
        flex: 1;

        .title {
            font-size: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-weight: normal;
            font-family: "microsoft yahei";
            color: #555;
        }

        .subtitle{
            height:100px;
            width:500px;
            margin-left:10px;
            font-weight:900;
            color:#555555;
        }

        .content {
            margin: 0 1rem;
            color: #555;
            font-size: 0.8vw;
            line-height: 25px;
            font: 'microsoft yahei';
        }

        .body-box-phone {
            background: #fff;
            .pxfix(1980);
            margin: 1.5rem auto;
        }

        .body-box {
            .pxfix(1040);
            margin: 1.5rem auto;
            //  flex:1;
            padding: 0 3vw;
            background: #fff;
        }

        .body-box:hover {
            box-shadow: 0 0 10px #888888;
        }
    }

    .footer {
        line-height: 50px;
        background: #2f3034;
        width: 100%;
        font-size: 12px;
        color: #fff;
        text-align: center;
    }
</style>
